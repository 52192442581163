import React from 'react'
import styled from '@emotion/styled'
require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  font-size: 1.125rem;
  line-height: 1.555555;
  color: ${props => props.theme.colors.grey_900};

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    line-height: 1.666666;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 1rem 0;
    font-weight: 600;
    line-height: 1.25;
    color: ${props => props.theme.colors.primary};
  }

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.875rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    margin: 0 0 2rem 0;

    &:first-of-type {
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        margin-bottom: 3rem;
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    font-weight: 600;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }

  del {
    text-decoration: line-through;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      margin-bottom: 1rem;
      list-style: disc;
      list-style-position: inside;
      &:last-child {
        margin: 0;
      }

      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        list-style-position: outside;
      }
    }
  }

  ol {
    li {
      margin-bottom: 1rem;
      list-style: decimal;
      list-style-position: inside;
      &:last-child {
        margin: 0;
      }

      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        list-style-position: outside;
      }
    }
  }

  hr {
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 1em;

    p,
    p:first-of-type {
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 4px;
    background: ${props => props.theme.colors.muted} !important;
    span {
      background: inherit !important;
    }
  }

  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 1rem;
    line-height: 1.5;
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody
