import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'

const Homepage = ({ data, pageContext }) => {
  const { title, metaDescription, body } = data.contentfulPage
  const posts = data.allContentfulPost.edges

  const HomepagePosts = styled.div`
    border-top: 1px solid ${props => props.theme.colors.secondary};
    margin-top: 2rem;
    padding-top: 2rem;

    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      margin-top: 4rem;
    }

    h2 {
      margin: 0 0 1.5rem 0;
      font-size: 1.5rem;
      line-height: 1.25;
      font-weight: 600;
      color: ${props => props.theme.colors.primary};

      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        font-size: 1.5rem;
      }
    }
  `

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
      />
      <Container>
        <PageBody body={body} />

        <HomepagePosts>
          <h2>Latest posts</h2>
          {/* <Link to="blog">View all</Link> */}
        </HomepagePosts>
        <CardList>
          {posts.map(({ node: post }) => (
            <Card key={post.id} {...post} basePath="blog" />
          ))}
        </CardList>
      </Container>
    </Layout>
  )
}

export default Homepage

export const query = graphql`
  query HomeQuery {
    contentfulPage(slug: { eq: "home" }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`
